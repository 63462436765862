import React from 'react';
import PropTypes from 'prop-types';

import CheckNegative from 'static/images/icons/check-negative.svg';
import { CheckPositive } from 'ui/svg';
import {
  CheckListContainer,
  CheckListTitle,
  CheckListItemContainer,
  List,
  ListItem,
} from './styles';

const CheckList = ({ title, pointsList, isPositive, colorCheck }) => {
  return (
    <CheckListContainer>
      <CheckListTitle>{title}</CheckListTitle>
      <List>
        {pointsList.map(point => (
          <ListItem key={point}>
            <CheckListItemContainer>
              {isPositive ? (
                <CheckPositive color={colorCheck} />
              ) : (
                <img src={CheckNegative} alt="negativeI on" />
              )}
              {point}
            </CheckListItemContainer>
          </ListItem>
        ))}
      </List>
    </CheckListContainer>
  );
};

CheckList.propTypes = {
  title: PropTypes.string,
  pointsList: PropTypes.array.isRequired,
  isPositive: PropTypes.bool,
  colorCheck: PropTypes.string,
};

CheckList.defaultProps = {
  isPositive: true,
};

export default CheckList;
