import React from 'react';

const consultancyProjectFit = [
  {
    image: 'architecture-consultancy',
    title: 'Software Architecture Consultancy',
    description:
      "Complex microservices or cloud architecture? We'll work with your team to configure and refine your software. We can define components, set up pipelines or do a trade-off analysis. We'll help you kill bottlenecks and make the right choices for your product, so you can scale without stress.",
    checklist: [
      <p>Architecture diagrams</p>,
      <p>Scalability options</p>,
      <p>Working proof of concept</p>,
    ],
  },
  {
    image: 'engineering-consultancy',
    title: 'Data Engineering Consultancy',
    description:
      "Overloaded with data? We'll help you make sense of the mess. We can model, gather, integrate and visualize your data in interactive dashboards. As well as validating costs and business limitations. So you can make informed decisions that will boost your growth.",
    checklist: [
      <p>Operational cost analysis</p>,
      <p>Data modeling</p>,
      <p>Working proof of concept</p>,
    ],
  },
  {
    image: 'research-development',
    title: 'Research & Development',
    description:
      "Got a groundbreaking idea but not sure if it's technically possible? Wonder no more. Our expert consultants can evaluate your tech, analyze its feasibility and help you create a proof of concept. Giving you the clarity and confidence you need to move forward — or run a mile.",
    checklist: [
      <p>Technical investigation</p>,
      <p>Recommendations reports</p>,
      <p>Working proof of concept</p>,
    ],
  },
  {
    image: 'rapid-prototyping',
    title: 'Rapid Prototyping',
    description:
      "If you've got an idea you're ready to test, we'll design and develop a functional prototype in a 6-week sprint. It will look and feel exactly like your finished product. So you can play around, validate the tech, and get the user insights you need from your prospects—fast!",
    checklist: [<p>High fidelity prototype</p>, <p>User testing</p>],
    callToAction: {
      title: 'Need something more robust?',
      linkText: 'Check out our MVP development service ->',
      link: '/services/mvp-development',
    },
  },
  {
    image: 'consultancy-help',
    title: 'We can also help with',
    checklist: [<p>DevOps & infrastructure automation</p>,
    <p>Front-end consultancy</p>,
    <p>UI/UX consultancy</p>,
    <p>Your project?</p>]
  },
];

export default consultancyProjectFit;
