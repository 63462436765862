import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.newTheme.spacing(10)};
  position: relative;
  background: linear-gradient(78.53deg, rgba(0, 0, 0, 0.8) 45.72%, rgba(0, 0, 0, 0) 65.73%);

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    justify-content: center;
    background: linear-gradient(78.53deg, rgba(0, 0, 0, 0.7) 39.72%, rgba(0, 0, 0, 0.6) 65.73%);
  }
`;

export const InformationContainer = styled.div`
  width: 40%;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.desktop}) {
    width: 40%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    width: 45%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 60%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const ImgContainer = styled.div`
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.h6`
  margin: 0;
  color: ${({ theme }) => theme.newTheme.color.white};
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title5.size};
    line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.newTheme.color.white};
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  weight: ${({ theme }) => theme.newTheme.font.weight.regular};

  span {
    font-size: ${({ theme }) => theme.newTheme.font.title6.size};
    line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    weight: ${({ theme }) => theme.newTheme.font.weight.regular};
    color: ${({ theme }) => theme.newTheme.color.white};
    display: block;
    margin: ${({ theme }) => `${theme.newTheme.spacing(10)} 0 ${theme.newTheme.spacing(4)} 0`};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;
