import styled from 'styled-components';

const Card = styled.div`
  ${({ theme }) => `padding: ${theme.newTheme.spacing(12)} ${theme.newTheme.spacing(16)}`};
  max-width: 48.5em;
  ${({ theme }) => `margin: ${theme.newTheme.spacing(6)} auto`};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.newTheme.border.radius.medium};
  box-shadow: ${({ theme }) => theme.newTheme.shadow.main};
  background-color: ${({ theme }) => theme.newTheme.color.white};
  position: relative;
  z-index: 9;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.newTheme.spacing(6)}`};
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.smallMobile}) {
    ${({ theme }) => `padding: ${theme.newTheme.spacing(6)} ${theme.newTheme.spacing(4)}`};
  }

  h5 {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
      position: relative;
      z-index: 9;

      b {
        display: block;
      }
    }
  }
`;

export default Card;
