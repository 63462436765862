import React from 'react';

import theme from 'styles/theme';
import Image from 'core/image';
import Link from 'atomic-design/atoms/link';
import Button from 'atomic-design/atoms/button';
import PageSection from 'atomic-design/atoms/page-section';
import { Card, InformationContainer, ImgContainer, Title, Description } from './styles';

const ImgAndInformationCard = () => (
  <Card>
    <ImgContainer>
      <Image alt="an Image" partialPathName="consultancy-5000" width="100%" fluid />
    </ImgContainer>

    <PageSection padding={`${theme.newTheme.spacing(20)} 0`}>
      <InformationContainer>
        <Title>Two (or more) heads are better than one</Title>
        <Description>
          Software isn't built in a silo, and bumping heads against a problem alone gets pretty
          painful. Deliver better results by bringing in a fresh perspective. And if you need more
          hands-on time? We can come to you and collaborate on-site to get you unstuck faster.
          <span>Get to know us better?</span>
        </Description>
        <Link to="/about-us" rel="noreferrer">
          <Button variant="contained">Meet the team</Button>
        </Link>
      </InformationContainer>
    </PageSection>
  </Card>
);

export default ImgAndInformationCard;
