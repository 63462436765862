import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.long};

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin-bottom: ${({ theme }) => theme.spacing.large};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.large};
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  order: ${({ invertOrder }) => (invertOrder ? 1 : 0)};
  width: 60%;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.fonts.sizes.headings.heading4};
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: 600;
  line-height: ${({ theme }) => theme.spacing.spacing};
  margin: 0;

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin-top: ${({ theme }) => theme.spacing.spacing20};
    font-size: ${({ theme }) => theme.spacing.spacing25};
    line-height: ${({ theme }) => theme.spacing.spacing25};
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.main};

  span {
    font-weight: 700;
    margin-left: ${({ theme }) => theme.spacing.smallest};
  }

  @media only screen and (${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: ${({ theme }) => theme.spacing.medium};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
    margin-top: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;
