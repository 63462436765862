import React from 'react';
import PropTypes from 'prop-types';

import { ZigzagIcon } from 'ui/svg';
import theme from 'styles/theme';
import { Content, Description, IconContainer, TextContainer, Title } from './styles';

const AgileInfoItem = ({ title, description, invertOrder, iconColor }) => {
  return (
    <Content>
      <TextContainer invertOrder={invertOrder}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      <IconContainer>
        <ZigzagIcon color={iconColor} />
      </IconContainer>
    </Content>
  );
};

AgileInfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  invertOrder: PropTypes.bool,
  iconColor: PropTypes.string,
};

AgileInfoItem.defaultProps = {
  invertOrder: false,
  iconColor: theme.newTheme.color.background.grey,
};

export default AgileInfoItem;
