import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-grid-system';
import Work from 'atomic-design/molecules/work';
import MissingDataCard from 'atomic-design/atoms/missing-data-card';
import { MainContainer, ContainerGrid } from './styles';
import { validateCaseStudyEntry } from '../../../../common/validations';

const WorkEntries = ({ works }) => {
  const requiredFields = ['title', 'subtitle', 'slug', 'LimitedAbstract', 'thumbnail'];

  return (
    <MainContainer>
      <Container fluid={true} component={ContainerGrid}>
        <Row>
          {works.map(work => (
            validateCaseStudyEntry(work.node) ?
              <Col xs={12} sm={12} md={12} lg={6}>
                <Work
                  key={work.node.slug}
                  slug={work.node.slug}
                  contentful_id={work.node.thumbnail.contentful_id}
                  title={work.node.title}
                  subtitle={work.node.subtitle}
                  type={work.node.LimitedAbstract}
                  height="15rem"
                  maxWidthText="55%"
                />
              </Col> :
              <Col xs={12} sm={12} md={12} lg={6}>
                <MissingDataCard key={work.node?.slug && work.node?.slug} obj={work.node} reqFields={requiredFields}></MissingDataCard>
              </Col>))}
        </Row>
      </Container>
    </MainContainer>
  );
};

WorkEntries.propTypes = {
  works: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        thumbnail: PropTypes.shape({
          contentful_id: PropTypes.string.isRequired,
        }),
        title: PropTypes.string,
      }),
    })
  ),
};

WorkEntries.defaultProps = {
  works: [],
};

export default WorkEntries;
