import React from 'react';
import PropTypes from 'prop-types';

import Image from 'core/image';
import MainContainer from 'atomic-design/atoms/main-container';
import {
  Container,
  ImgContainer,
  InformationContainer,
  ImgMainContainer,
  ContainerInfo,
} from './styles';

const ImgAndChildren = ({ image, altImage, leftImage, children, last }) => {
  return (
    <Container leftImage={leftImage}>
      <ImgMainContainer leftImage={leftImage}>
        <ImgContainer>
          <Image alt={altImage} partialPathName={image} width="100%" height="100%" fluid />
        </ImgContainer>
      </ImgMainContainer>

      <MainContainer>
        <ContainerInfo leftImage={leftImage} last={last}>
          <InformationContainer leftImage={leftImage}>{children}</InformationContainer>
        </ContainerInfo>
      </MainContainer>
    </Container>
  );
};

ImgAndChildren.propTypes = {
  image: PropTypes.element.isRequired,
  altImage: PropTypes.string.isRequired,
  leftImage: PropTypes.bool,
  last: PropTypes.bool
};

ImgAndChildren.defaultProps = {
  leftImage: true,
  last: false,
};

export default ImgAndChildren;
