import styled, { css } from 'styled-components';

export const CallToActionContainer = styled.div`
  width: 100%;
`;

export const CallToActionBackground = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  border-top: ${({ theme }) => `${theme.newTheme.spacing(20)} solid white`};
  border-left: 100vw solid transparent;

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    border-top: ${({ theme }) => `${theme.newTheme.spacing(10)} solid white`};
  }
`;

export const CallToActionTitle = styled.h5`
  font-size: ${({ theme }) => `${theme.newTheme.font.title2.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title2.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
  color: ${({ theme }) => `${theme.newTheme.color.white}`};
  margin-bottom: 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 90%;
    font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.bold}`};
  }
`;

export const CallToActionText = styled.h6`
  margin: ${({ theme }) => `${theme.newTheme.spacing(4)} 0`};
  width: 55%;
  font-size: ${({ theme }) => `${theme.newTheme.font.title4.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.medium}`};
  color: ${({ theme }) => `${theme.newTheme.color.white}`};

  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.medium}`};
    width: 80%;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => `${theme.newTheme.font.title5.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title5.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.medium}`};
    width: 90%;
  }
`;
