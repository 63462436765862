import styled, { css } from 'styled-components';

const backgroundColorStyles = (theme, variant) =>
  ({
    light: css`
      background: ${theme.newTheme.color.white} !important;
      box-shadow: ${theme.newTheme.shadow.main};
      transition: ${theme.newTheme.transition.main};

      button {
        margin: 0 !important;
        background: ${theme.newTheme.color.gradient.text};
        font-weight: 500;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: ${theme.newTheme.transition.main};
      }

      span {
        background: ${theme.newTheme.color.gradient.text};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        transition: ${theme.newTheme.transition.main};

        button {
          background: ${theme.newTheme.color.background.grey};
          font-weight: 500;
          background-clip: none;
          -webkit-background-clip: none;
          -webkit-text-fill-color: ${theme.newTheme.color.background.grey};
          transition: ${theme.newTheme.transition.main};
        }
      }

      &:active {
        transition: ${theme.newTheme.transition.main};

        button {
          background: ${theme.newTheme.color.divider};
          font-weight: 500;
          background-clip: none;
          -webkit-background-clip: none;
          -webkit-text-fill-color: ${theme.newTheme.color.divider};
          transition: ${theme.newTheme.transition.main};
        }
      }
    `,
    dark: css`
      button {
        color: ${theme.newTheme.color.white};
        background: #4a4a4a !important;

        span {
          color: ${theme.newTheme.color.white};
        }

        &:active {
          opacity: 0.8;
        }

        &:hover {
          border: transparent;
          background-color: transparent !important;
          transition: ${theme.newTheme.transition.main};

          @media only screen and (${props => props.theme.newTheme.breakpoints.tablet}) {
            color: ${theme.newTheme.color.white};
            background: ${theme.newTheme.color.grey.main} !important;
          }
        }
      }
    `,
  }[variant]);

const BackgroundGradient = styled.div`
  ${({ theme, variant }) => backgroundColorStyles(theme, variant)};
  background: ${({ theme }) => theme.newTheme.color.gradient.text};
  color: ${({ theme }) => theme.newTheme.color.white};
  border-radius: ${({ theme }) => theme.newTheme.border.radius.button};
  display: inline-flex;
  align-items: center;
  justify-content: center;

  button {
    ${({ theme }) => `padding: ${theme.newTheme.spacing(3)} ${theme.newTheme.spacing(6)}`};
    border-radius: ${({ theme }) => theme.newTheme.border.radius.button};
    border: transparent;
    margin: 2px;
  }
`;

export default BackgroundGradient;
