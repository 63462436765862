import React from 'react';
import MetaTags from 'core/meta-tags/index';
import { graphql } from 'gatsby';
import Layout from 'core/layout';
import Link from 'atomic-design/atoms/link';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { renderLineBreak } from 'common/string-helpers';
import PageSection from 'atomic-design/atoms/page-section';
import theme from 'styles/theme';
import PageHeader from 'atomic-design/atoms/page-header';
import Button from 'atomic-design/atoms/button';
import Overrun from 'atomic-design/organisms/overrun-section';
import Testimonial from 'atomic-design/molecules/testimonial';
import ListDivider from 'atomic-design/atoms/list-divider';
import TestimonialCard from 'atomic-design/molecules/testimonial-card';
import ImgAndInformationCard from 'atomic-design/organisms/img-and-information-card';
import BuiltSection from 'atomic-design/organisms/built-section';
import CallToAction from 'atomic-design/molecules/call-to-action';
import CallToActionDiagonal from 'atomic-design/organisms/call-to-action-diagonal';
import iconList from 'domain/solidPrinciples';
import AgileInfoItem from 'atomic-design/molecules/agile-info-item';
import ImgAndChildren from 'atomic-design/molecules/img-and-children';
import CheckList from 'atomic-design/molecules/check-list';
import consultancyProjectFit from 'domain/consultancyProjectFit';
import AdjustEvaluate from 'static/images/illustrations/adjust-evaluate.svg';
import AdjustEvaluateMobile from 'static/images/illustrations/adjust-evaluate-mobile.svg';
import useIsMobile from 'hooks/useIsMobile';

import {
  PageHeaderContainer,
  TestimonialBox,
  TestimonialContainer,
  VisionTitle,
  VisionText,
  SolidTitle,
  SolidText,
  SolidItem,
  SolidList,
  SolidItemText,
  FitHeader,
  FitDescription,
  FitTitle,
  FitList,
  CallToActionFit,
  ImgContainer,
  Diagonal,
  ListContainer,
  TextContainer,
  BeginningContainer,
} from 'styles/pages/consultancy-styles';

export const ConsultancyQuery = graphql`
  query ConsultancyQuery {
    allContentfulPage(filter: { url: { eq: "consultancy" } }) {
      edges {
        node {
          headerTitle {
            json
          }
          headerSubtitle
          headerDescription {
            headerDescription
          }
          metatagImage {
            file {
              url
            }
          }
          metatagTitle
          metatagDescription
        }
      }
    }
    allContentfulTestimonialFeatured(
      filter: { pageId: { eq: "consultancy" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          title {
            json
          }
          description {
            description
          }
          author {
            image {
              contentful_id
            }
            name
            role
          }
        }
      }
    }
    allContentfulCaseStudy(filter: { pageId: { eq: "consultancy" } }, sort: { fields: publishedDate, order: DESC  }, limit: 2) {
      edges {
        node {
          title
          slug
          LimitedAbstract
          thumbnail {
            contentful_id
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const Consultancy = ({ data, location }) => {
  const pageHeaderData = data.allContentfulPage.edges[0].node;
  const testimonialFeatured1 = data.allContentfulTestimonialFeatured.edges[0].node;
  const testimonialFeatured2 = data.allContentfulTestimonialFeatured.edges[1].node;
  const caseStudiesData = data.allContentfulCaseStudy.edges;
  const metatagImage = data.allContentfulPage.edges[0].node.metatagImage.file.url;
  const metatagDescription = data.allContentfulPage.edges[0].node.metatagDescription;
  const metatagTitle = data.allContentfulPage.edges[0].node.metatagTitle;

  const solidArgs = {
    size: '120px',
    color: '#535353',
  };
  const solidIcons = iconList({ ...solidArgs });
  const isMobile = useIsMobile('mobile');

  const imageVision = isMobile ? AdjustEvaluateMobile : AdjustEvaluate;

  return (
    <Layout path={location.pathname} bodyType="xWide">
      <MetaTags
        title={metatagTitle}
        description={metatagDescription}
        charSet="utf-8"
        image={metatagImage}
        viewport="width=device-width, initial-scale=1"
        type="website"
      />

      <PageSection>
        <PageHeaderContainer>
          <PageHeader
            title={pageHeaderData.headerSubtitle}
            subTitle={documentToReactComponents(pageHeaderData.headerTitle.json)}
            description={renderLineBreak(pageHeaderData.headerDescription.headerDescription)}
            color={theme.newTheme.color.service.consultancy.main}
          />
        </PageHeaderContainer>
        <Link to="/contact" rel="noreferrer">
          <Button variant="contained">Propel my project forward</Button>
        </Link>
      </PageSection>

      <Overrun
        padding={`${theme.newTheme.spacing(40)} 0 ${theme.newTheme.spacing(30)} 0`}
        background={theme.newTheme.color.service.consultancy.background}
        margin={`${theme.newTheme.spacing(12)} 0`}
        mobilePadding={`${theme.newTheme.spacing(30)} 0 ${theme.newTheme.spacing(10)} 0`}
      >
        Going from concept to creation is challenging enough. Dodge the development roadblocks with
        hands-on support <span>from our team of seasoned engineers.</span>
      </Overrun>

      <PageSection>
        <TestimonialContainer>
          <ListDivider />
          <TestimonialBox>
            <Testimonial
              title={documentToReactComponents(testimonialFeatured1.title.json)}
              description={testimonialFeatured1.description.description}
              image={testimonialFeatured1.author.image.contentful_id}
              authorName={testimonialFeatured1.author.name}
              authorRol={testimonialFeatured1.author.role}
              alt={`${testimonialFeatured1.author.name} - ${testimonialFeatured1.author.rol}`}
              quoteColor={theme.newTheme.color.service.consultancy.background}
              textAlign="center"
            />
          </TestimonialBox>
          <ListDivider />
        </TestimonialContainer>
      </PageSection>

      <PageSection
        background={theme.newTheme.color.service.consultancy.background}
        margin={`${theme.newTheme.spacing(28)} 0 0 0`}
        padding={`0 0 ${theme.newTheme.spacing(28)} 0`}
        mobilePadding={`0 0 ${theme.newTheme.spacing(15)} 0`}
      >
        <Diagonal />
        <VisionTitle>
          You bring the vision — or problem.
          <span> We'll bring the expertise.</span>
        </VisionTitle>
        <VisionText>
          When you're wading through a sticky problem, any dev won't do. With more years' experience
          than we'd like to admit, our consultants have almost seen it all. Working across
          industries, architectures and technical practices, we can recommend the best approach to
          get your project unstuck — fast.
        </VisionText>
        <ImgContainer>
          <img src={imageVision} alt="" />
        </ImgContainer>
      </PageSection>

      <PageSection
        margin={`${theme.newTheme.spacing(30)} 0 0 0}`}
        mobileMargin={`${theme.newTheme.spacing(15)} 0 0 0}`}
      >
        <FitHeader>
          <AgileInfoItem
            title={['Find your', <span>perfect project fit</span>]}
            description="All our software consulting projects are 100% customizable, because one size doesn’t fit all. 
            But take a look at the kinds of challenges we can solve together:"
          />
        </FitHeader>
      </PageSection>

      <PageSection containerSize="fluid">
        <FitList>
          {consultancyProjectFit.map((item, index, consultancyProjectFit) => (
            <li key={item.index}>
              {consultancyProjectFit.length - 1 != index ? (
                <ImgAndChildren image={item.image} leftImage={index % 2}>
                  <FitTitle>{item.title}</FitTitle>
                  <FitDescription>{item.description}</FitDescription>
                  <CheckList
                    pointsList={item.checklist}
                    isPositive
                    colorCheck={theme.newTheme.color.service.consultancy.main}
                  />
                  {item.callToAction && (
                    <CallToActionFit>
                      <p>{item.callToAction.title}</p>
                      <Link to={item.callToAction.link}>{item.callToAction.linkText}</Link>
                    </CallToActionFit>
                  )}
                </ImgAndChildren>
              ) : (
                <ImgAndChildren image={item.image} leftImage={index % 2} last={true}>
                  <TextContainer>
                    <h6>
                      And that's just <span>the beginning...</span>
                    </h6>
                  </TextContainer>
                  <CheckList
                    title={<h6>{item.title}</h6>}
                    pointsList={item.checklist}
                    isPositive
                    colorCheck={theme.newTheme.color.service.consultancy.main}
                  />
                </ImgAndChildren>
              )}
            </li>
          ))}
        </FitList>
      </PageSection>

      <PageSection
        padding={`${theme.newTheme.spacing(15)} 0 ${theme.newTheme.spacing(10)} 0`}
        mobilePadding={`${theme.newTheme.spacing(1)} 0 ${theme.newTheme.spacing(10)} 0`}
      >
        <CallToAction
          sectionTitle="Try us on for size"
          buttonTitle="Let's get started"
          to="/contact"
        />
      </PageSection>

      <TestimonialContainer>
        <PageSection
          background={theme.newTheme.color.service.consultancy.background}
          margin={`${theme.newTheme.spacing(40)} 0 0 0`}
          padding={`${theme.newTheme.spacing(30)} 0`}
          mobileMargin={`${theme.newTheme.spacing(12)} 0 0 0`}
          mobilePadding={`${theme.newTheme.spacing(15)} 0`}
        >
          <TestimonialCard
            title={documentToReactComponents(testimonialFeatured2.title.json)}
            description={testimonialFeatured2.description.description}
            image={testimonialFeatured2.author.image.contentful_id}
            authorName={testimonialFeatured2.author.name}
            authorRol={testimonialFeatured2.author.role}
            alt={`${testimonialFeatured2.author.name} - ${testimonialFeatured2.author.rol}`}
            textAlign="center"
            quoteColor={theme.newTheme.color.service.consultancy.background}
            nameColor={theme.newTheme.color.service.consultancy.main}
          />
        </PageSection>
      </TestimonialContainer>

      <ImgAndInformationCard />

      <PageSection
        margin={`${theme.newTheme.spacing(25)} 0`}
        mobileMargin={`${theme.newTheme.spacing(12)} 0`}
      >
        <SolidText>OUR GUARANTEE</SolidText>
        <SolidTitle>
          SOLID principles
          <span> QUALITY craft and products </span>
          that are:
        </SolidTitle>
        <SolidList>
          {solidIcons.map(icon => (
            <SolidItem key={icon.name}>
              <icon>{icon.icon}</icon>
              <SolidItemText>{icon.name}</SolidItemText>
            </SolidItem>
          ))}
        </SolidList>
      </PageSection>

      <PageSection
        padding={`${theme.newTheme.spacing(30)}  0`}
        mobilePadding={`${theme.newTheme.spacing(15)}  0 ${theme.newTheme.spacing(25)}  0`}
      >
        <BuiltSection
          caseStudies={caseStudiesData}
          titleColor={theme.newTheme.color.service.consultancy.main}
        />
        <CallToAction
          sectionTitle="There's more where that came from."
          buttonTitle="Take a look at our work "
          to="/work"
        />
      </PageSection>

      <CallToActionDiagonal
        background={theme.newTheme.color.gradient.background.primary.main}
        padding={`${theme.newTheme.spacing(16)} 0 ${theme.newTheme.spacing(16)} 0`}
        title="Ready to move your product forward?"
        description="Whether it's for a question or a quote, we'd love to hear from you"
        buttonText="Let's talk"
      />
    </Layout>
  );
};

export default Consultancy;
