import styled from 'styled-components';

export const TitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(15)};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
  }
`;

export const ColGrid = styled.div`
  @media only screen and (${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(8)};
  }
`;

export const BuiltSectionTitle = styled.h5`
  margin: 0;
  font-size: ${({ theme }) => `${theme.newTheme.font.title3.size}`};
  line-height: ${({ theme }) => `${theme.newTheme.font.title3.lineHeight}`};
  font-weight: ${({ theme }) => `${theme.newTheme.font.weight.semiBold}`};
  color: ${({ theme }) => `${theme.newTheme.color.black}`};

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => `${theme.newTheme.font.title4.lineHeight}`};
    margin-bottom: 0;
  }

  span {
    b {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      background: ${({ theme }) => theme.newTheme.color.gradient.text};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      display: block;
    }
  }
`;

export const BuiltSectionSubtitle = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  margin-top: ${({ theme }) => theme.newTheme.spacing(10)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin-top: ${({ theme }) => theme.newTheme.spacing(5)};
  }
`;

export const ContainerGrid = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.newTheme.spacing(2)} 0 !important`};
  margin: 0 !important;
`;
