import React from 'react';
import PropTypes from 'prop-types';

import Link from 'atomic-design/atoms/link';

import Button from 'atomic-design/atoms/button';
import BackgroundGradient from './styles';

const ButtonGradient = ({ to, title, ariaLabel, variant, fullWidth, disabled }) => {
  return (
    <Link to={to}>
      <BackgroundGradient variant={variant}>
        <Button aria-label={ariaLabel} fullWidth={fullWidth} disabled={disabled}>
          <span>{title}</span>
        </Button>
      </BackgroundGradient>
    </Link>
  );
};

ButtonGradient.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

ButtonGradient.defaultProps = {
  ariaLabel: '',
  variant: 'dark',
  fullWidth: false,
  disabled: false,
};

export default ButtonGradient;
