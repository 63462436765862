import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(35)};

  ${({ leftImage }) => (leftImage ? 'flex-direction: row' : 'flex-direction: row-reverse')};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(15)};
  }

  h2 {
    color: ${({ theme }) => `${theme.newTheme.color.primary.main}`};
    font-size: ${({ theme }) => `${theme.newTheme.font.title2.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.title2.lineHeight}`};
  }
`;

export const ImgMainContainer = styled.div`
  position: absolute;

  ${({ leftImage }) => (leftImage ? 'left: 0' : 'right: 0')};
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 100%;
    position: relative;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(10)};
  }
`;

export const ImgContainer = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    display: flex;
    width: 100%;
    height: ${({ theme }) => theme.newTheme.spacing(100)};

    min-width: 0;
    min-height: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    height: 100%;
    min-width: 0;
    min-height: 0;
    img {
      flex-shrink: 0;
    }
  }
`;

export const ContainerInfo = styled.div`
  display: flex;

  ${({ leftImage }) => (leftImage ? 'justify-content: flex-end' : 'justify-content: flex-start')};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tabletLandscape}) {
    ${({ last }) => (last && 'min-width: 50em;')};
   }
 ${({ last }) => (last && 'min-width: 70em;')};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    justify-content: center;
  }
`;

export const InformationContainer = styled.div`
  height: 100%;
  width: 50%;

  ${({ leftImage, theme }) =>
    leftImage
      ? `padding: 0 0 0 ${theme.newTheme.spacing(32)}`
      : `padding: 0 ${theme.newTheme.spacing(32)} 0 0`};

  div {
    padding: 0;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    width: 75%;
    padding: 0;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
  }
`;
