import styled from 'styled-components';

export const CheckListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 0.75em;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export const CheckListTitle = styled.div`
  h6 {
    margin: 0;
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(3)};
    color: ${({ theme }) => theme.newTheme.color.black};

    i,
    u {
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    }
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(5)};

  img {
    height: ${({ theme }) => theme.newTheme.spacing(5)};
    width: ${({ theme }) => theme.newTheme.spacing(5)};
    position: relative;
    top: 0.1em;
    margin-right: ${({ theme }) => theme.newTheme.spacing(3)};
  }

  svg {
    min-height: ${({ theme }) => theme.newTheme.spacing(5)};
    min-width: ${({ theme }) => theme.newTheme.spacing(5)};
    position: relative;
    top: 0.1em;
    margin-right: ${({ theme }) => theme.newTheme.spacing(3)};
  }
`;

export const CheckListItemContainer = styled.div`
  display: flex;
  p {
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    margin: 0;

    u {
      color: ${({ theme }) => theme.newTheme.color.grey.main};
    }
  }

  a {
    font-size: ${({ theme }) => `${theme.newTheme.font.text2.size}`};
    line-height: ${({ theme }) => `${theme.newTheme.font.text2.lineHeight}`};
    font-weight: ${({ theme }) => `${theme.newTheme.font.weight.regular}`};
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    text-decoration: underline;
  }
`;
