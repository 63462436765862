import React from 'react';
import PropTypes from 'prop-types';

import theme from 'styles/theme';
import { ChevronArrow } from 'ui/svg';
import Link from 'atomic-design/atoms/link';
import Image from 'core/image';
import {
  Container,
  InformationContainer,
  WorkType,
  Title,
  Subtitle,
  CallToAction,
  IconContainer,
} from './styles';

const Work = ({ slug, contentful_id, title, subtitle, type, height, maxWidthText }) => (
  <Link to={`/work/${slug}`} rel={slug}>
    <Container height={height}>
      <Image
        alt={title}
        contentful_id={contentful_id}
        fromContentful
        mobileHeight="180px"
        smallMobileHeight="180px"
        height="inherit"
        padding="0"
        flex="1"
      />
      <InformationContainer maxWidthText={maxWidthText}>
        <WorkType>{type}</WorkType>
        <Title>{title}</Title>
        <CallToAction>
          <p>Read the case study</p>
          <IconContainer>
            <ChevronArrow color={theme.newTheme.color.grey.light} />
          </IconContainer>
        </CallToAction>
      </InformationContainer>
    </Container>
  </Link>
);

Work.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  contentful_id: PropTypes.string.isRequired,
  height: PropTypes.string,
  maxWidthText: PropTypes.string,
};

Work.defaultProps = {
  height: '',
  maxWidthText: '100%',
};

export default Work;
