import React from 'react';
import PropTypes from 'prop-types';
import { BuiltSectionTitle, BuiltSectionSubtitle, TitleContainer } from './styles';
import WorkEntries from 'atomic-design/organisms/work-entries';

const BuiltSection = ({ caseStudies, subtitle }) => {
  return (
    <>
      <TitleContainer>
        <BuiltSectionTitle>
          How
          <span>
            <b> we've helped them</b>
          </span>
        </BuiltSectionTitle>
        <BuiltSectionSubtitle>{subtitle}</BuiltSectionSubtitle>
      </TitleContainer>
      <WorkEntries works={caseStudies} />
    </>
  );
};

BuiltSection.propTypes = {
  caseStudies: PropTypes.array.isRequired,
};

export default BuiltSection;
