import React from 'react';
import PropTypes from 'prop-types';

import PageSection from 'atomic-design/atoms/page-section';
import ButtonGradient from 'components/atomic-design/atoms/button-gradient';
import {
  CallToActionContainer,
  CallToActionBackground,
  CallToActionTitle,
  CallToActionText,
} from './styles';

const CallToActionDiagonal = ({
  background,
  padding,
  title,
  description,
  buttonText,
  withDiagonal,
}) => {
  return (
    <CallToActionContainer>
      {withDiagonal && <CallToActionBackground />}
      <PageSection background={background} padding={padding}>
        <CallToActionTitle>{title}</CallToActionTitle>
        <CallToActionText>{description}</CallToActionText>
        <ButtonGradient variant="light" title={buttonText} to="/contact" />
      </PageSection>
    </CallToActionContainer>
  );
};

CallToActionDiagonal.propTypes = {
  background: PropTypes.string,
  padding: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  withDiagonal: PropTypes.bool,
};

CallToActionDiagonal.defaultProps = {
  background: '',
  padding: '',
  description: '',
  withDiagonal: true,
};

export default CallToActionDiagonal;
