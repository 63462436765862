import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.newTheme.spacing(2)};
  button {
    width: fit-content;
  }
`;

export const ContainerGrid = styled.div`
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
`;
