import React from 'react';
import { Flexible, Scalable, Secure, Reliable, HighPerforming } from 'ui/svg';

const iconList = ({ size, color }) => [
  {
    name: 'High-performing',
    icon: <HighPerforming size={size} color={color} />,
  },
  {
    name: 'Reliable',
    icon: <Reliable size={size} color={color} />,
  },
  {
    name: 'Scalable',
    icon: <Scalable size={size} color={color} />,
  },
  {
    name: 'Flexible',
    icon: <Flexible size={size} color={color} />,
  },
  {
    name: 'Secure',
    icon: <Secure size={size} color={color} />,
  },
];

export default iconList;
