import styled from 'styled-components';

export const PageHeaderContainer = styled.section`
  padding-bottom: ${({ theme }) => theme.newTheme.spacing(12)};
  b {
    display: block;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding-bottom: ${({ theme }) => theme.newTheme.spacing(6)};
  }
`;

export const TestimonialBox = styled.div`
  ${({ theme }) => `padding: ${theme.newTheme.spacing(12)} ${theme.newTheme.spacing(16)}`};
  max-width: 48.5em;
  margin: ${({ theme }) => theme.newTheme.spacing(16)} auto;
  overflow: hidden;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  h5 {
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
      position: relative;
      z-index: 9;
    }
  }
  svg {
    opacity: 0.5;
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      position: absolute;
      z-index: 0;
      transform: translateX(-100px) translateY(0);
    }
  }

  q {
    font-size: ${({ theme }) => theme.newTheme.font.text1.sizes};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text2.sizes};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    }
  }
  figcaption {
    span {
      color: ${({ theme }) => theme.newTheme.color.service.consultancy.main};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    ${({ theme }) => `padding: ${theme.newTheme.spacing(6)}`};
    margin: ${({ theme }) => theme.newTheme.spacing(10)} auto;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.smallMobile}) {
    ${({ theme }) => `padding: ${theme.newTheme.spacing(6)} ${theme.newTheme.spacing(4)}`};
  }
`;

export const TestimonialContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h5 b {
    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      display: block;
    }
  }

  div:nth-child(2) {
    margin: 0 auto;
  }

  hr {
    height: 2px;
    max-width: 52em;
    background: ${({ theme }) => theme.newTheme.color.service.consultancy.background};
  }

  hr:nth-child(3) {
    margin-left: auto;
  }
`;

export const DiagonalBackground = styled.div`
  left: 0;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 35em;
  background: ${({ theme }) => theme.newTheme.color.service.consultancy.background};
  transform: skewY(7deg);
`;

export const VisionTitle = styled.h3`
  padding-top: ${({ theme }) => theme.newTheme.spacing(30)};
  margin: 0;
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  width: 70%;

  span {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    width: 70%;
    display: block;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    width: 100%;

    span {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      width: 100%;
      display: inline;
    }
  }
`;

export const VisionText = styled.p`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  width: 52%;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    width: 100%;
  }
`;

export const SolidTitle = styled.h4`
  font-size: ${({ theme }) => theme.newTheme.font.title3.size};
  line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.newTheme.color.black};
  width: 50%;
  margin-bottom: ${({ theme }) => theme.newTheme.spacing(25)};

  span {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};

    background: ${({ theme }) => theme.newTheme.color.gradient.text};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title4.size};
    line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
    width: 100%;
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(15)};

    span {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      width: 100%;
      display: block;
    }
  }
`;

export const SolidText = styled.h5`
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  color: ${({ theme }) => theme.newTheme.color.grey.light};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    width: 100%;
  }
`;

export const SolidList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.newTheme.spacing(17)};
  }
`;

export const SolidItem = styled.li`
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.newTheme.spacing(6)};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.smallMobile}) {
    margin-bottom: ${({ theme }) => theme.newTheme.spacing(15)};
  }
`;

export const SolidItemText = styled.h6`
  font-size: ${({ theme }) => theme.newTheme.font.title6.size};
  line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
  color: ${({ theme }) => theme.newTheme.color.service.consultancy.main};
  margin: 0;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  }
`;

export const FitHeader = styled.div`
  h4 {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    color: ${({ theme }) => theme.newTheme.color.black};

    span {
      display: block;
      font-size: ${({ theme }) => theme.newTheme.font.title3.size};
      line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
      background: ${({ theme }) => theme.newTheme.color.gradient.text};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    width: 85%;
  }

  @media only screen and (${({ theme }) => theme.breakpoints.mobile}) {
    h4 {
      font-size: ${({ theme }) => theme.newTheme.font.title4.size};
      line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};

      span {
        display: block;
        font-size: ${({ theme }) => theme.newTheme.font.title4.size};
        line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
      }
    }

    p {
      width: 100%;
    }

    svg {
      display: none;
    }
  }
`;

export const FitTitle = styled.h6`
  color: ${({ theme }) => theme.newTheme.color.service.consultancy.main};
  font-size: ${({ theme }) => theme.newTheme.font.title4.size};
  line-height: ${({ theme }) => theme.newTheme.font.title4.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.desktop}){
    margin: 0;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.title5.size};
    line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
  }
`;

export const FitDescription = styled.p`
  ${({ theme }) => `margin: ${theme.newTheme.spacing(6)} 0`};
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-size: ${({ theme }) => theme.newTheme.font.text1.size};
  line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  }
`;

export const FitList = styled.ul`
  padding: 0;
  margin: 0;
`;

export const CallToActionFit = styled.div`
  p {
    font-size: ${({ theme }) => theme.newTheme.font.text1.size};
    line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};

    ${({ theme }) => `margin: 0 0 ${theme.newTheme.spacing(3)} 0`};

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text2.size};
      line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    }
  }

  a {
    font-size: ${({ theme }) => theme.newTheme.font.text2.size};
    line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    color: ${({ theme }) => theme.newTheme.color.service.consultancy.main};
    text-decoration: underline;

    @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
      font-size: ${({ theme }) => theme.newTheme.font.text3.size};
      line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
    }
  }
`;
export const ImgContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.newTheme.spacing(15)};

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    img {
      width: 90%;
      height: 100%;
    }
  }
`;

export const Diagonal = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  border-top: ${({ theme }) => `${theme.newTheme.spacing(20)} solid white`};
  border-left: 100vw solid transparent;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    border-top: ${({ theme }) => `${theme.newTheme.spacing(10)} solid white`};
  }
`;

export const BeginningContainer = styled.div`
  display: flex;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  h6 {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    color: ${({ theme }) => theme.newTheme.color.black};
   margin-top: 0;
  }

  span {
    font-size: ${({ theme }) => theme.newTheme.font.title3.size};
    line-height: ${({ theme }) => theme.newTheme.font.title3.lineHeight};
    background: ${({ theme }) => theme.newTheme.color.gradient.text};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.bold};
    display: block;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.desk}) {}
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    justify-content: flex-start;

    h6 {
      font-size: ${({ theme }) => theme.newTheme.font.title5.size};
      line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
      padding-bottom: 0;
    }
    span {
      font-size: ${({ theme }) => theme.newTheme.font.title5.size};
      line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;

  padding-left: ${({ theme }) => theme.newTheme.spacing(33)};

  h6 {
    font-size: ${({ theme }) => theme.newTheme.font.title5.size};
    line-height: ${({ theme }) => theme.newTheme.font.title5.lineHeight};
    color: ${({ theme }) => theme.newTheme.color.grey.main};
    font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  }

  p {
    padding-right: ${({ theme }) => theme.newTheme.spacing(3)};
  }

  svg {
    margin-left: ${({ theme }) => theme.newTheme.spacing(1)};
    margin-top: ${({ theme }) => theme.newTheme.spacing(0.5)};
  }

  li:last-child {
    display: flex;
    div {
      background: ${({ theme }) => theme.newTheme.color.service.consultancy.background};
      border-radius: ${({ theme }) => theme.newTheme.border.radius.small};
    }
    p {
      color: ${({ theme }) => theme.newTheme.color.service.consultancy.main};
    }
  }

  section {
    padding: 0;
    align-items: flex-start;
    h4 {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
      color: ${({ theme }) => theme.newTheme.color.grey.main};
      font-weight: ${({ theme }) => theme.newTheme.font.weight.semiBold};
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    width: 100%;
    padding-left: 0;

    ul {
      margin: 0;
    }

    h6 {
      font-size: ${({ theme }) => theme.newTheme.font.title6.size};
      line-height: ${({ theme }) => theme.newTheme.font.title6.lineHeight};
    }

    h4 {
      margin-top: 0;
      font-size: ${({ theme }) => theme.newTheme.font.text1.size};
      line-height: ${({ theme }) => theme.newTheme.font.text1.lineHeight};
    }
  }
`;
