import React from 'react';

import Testimonial from 'atomic-design/molecules/testimonial';
import Card from './styles';

const TestimonialCard = ({
  image,
  alt,
  title,
  description,
  authorName,
  authorRol,
  imgAlign = 'start',
  textAlign = 'center',
  quoteColor,
  nameColor,
}) => {
  return (
    <Card nameColor={nameColor}>
      <Testimonial
        title={title}
        description={description}
        image={image}
        authorName={authorName}
        authorRol={authorRol}
        alt={alt}
        textAlign={textAlign}
        imgAlign={imgAlign}
        quoteColor={quoteColor}
        nameColor={nameColor}
      />
    </Card>
  );
};

export default TestimonialCard;
